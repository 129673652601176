import * as React from 'react'
import {
    Box,
    Container
} from '@chakra-ui/react'
import { graphql } from "gatsby"
import { RichText } from 'prismic-reactjs'

import Seo from "../components/seo"
import BackgroundImage from "../components/Backgrounds/BackgroundImage"
import SidebarNav from "../components/SidebarNav/SidebarNav"
import MainContentBox from "../components/MainContentBox/MainContentBox"
import MobileNav from "../components/MobileNav/MobileNav"
import Footer from "../components/Footer/Footer"
import PageHero  from "../components/PageHero/PageHero"
import { Breadcrumb } from "gatsby-plugin-breadcrumb"
import { ChevronRightIcon } from "@chakra-ui/icons"
import { getImage } from "gatsby-plugin-image"

const PrivacyPolicy = ({ pageContext, location, data }) => {
    
    const pageLocation = location.pathname;
    const {
        breadcrumb: { crumbs },
    } = pageContext

    if (!data) return null
    const document = data.prismicPrivacyPolicy.data
  
    return (
        <>
            <Seo title="Privacy Policy" />
            <BackgroundImage
                BackgroundImage={getImage()}
            >
                <Container maxW="container.xl">
                    <MobileNav />
                    <Box paddingTop="20" width="100%">
              <SidebarNav 
                pathName={pageLocation}
              />
          <MainContentBox>
            <Box p="4" backgroundColor="rgba(255, 255, 255, 0.8)">
            <Breadcrumb
              textAlign="center"
              crumbs={crumbs}
              crumbSeparator={<ChevronRightIcon />}
              crumbLabel="Privacy Policy"
          />
          </Box>
          <Box p="4" backgroundColor="rgba(255, 255, 255, 0.8)">
          <div dangerouslySetInnerHTML={{__html:document.privacy_policy_html.raw[0].text}} />

          </Box>
            <Footer />
          </MainContentBox>
          </Box>
                </Container>
            </BackgroundImage>
        </>
    )
};

export const query = graphql`
query PrivacyPolicy {
    prismicPrivacyPolicy {
      data {
        page_title {
          text
        }
        privacy_policy_html {
          raw
          html
        }
      }
    }
  }  
`


export default PrivacyPolicy;

